<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6" class="mb-1">
          <b-card class="p-5 mb-5 rounded">
            <div class="clearfix">
              <h3 class="display-4 mr-4">Email verificado</h3>
              <p class="text-muted">
                <a href="/login">pulsa aqui para volver a la pagina principal. </a>
              </p>
            </div>
          </b-card>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Page404",
};
</script>
<style>
.text-muted a {
  color: #f68d1e !important; 
  font-size: 18px;
}

.text-muted a:hover{
  color: #1E31F6 !important;
  text-decoration: underline;
}
</style>
